import * as R from "ramda"
// import { createSelector } from "reselect"
import { ThunkAction } from "redux-thunk"
import apiClient, { ApiClientCallbackResponse } from "services/apiClient"
import initialState, { StateShape } from "models/initialState"
import { Actions as uiActions } from "models/ui"
import { selectListOfReviewsFromReviewIdList } from "models/reviews"
import { extractErrorMessages, makeUserFriendlyErrorMessage } from "utils/errors"
import { normalizeEventsFromServer, isEventInstance, extractEventsFromProvider } from "utils/events"
import { isString, isNumber } from "utils/parseUtils"
import { isUserInstance } from "utils/users"
import { EMAIL_NEEDS_CONFIRMATION_MESSAGE } from "constants/errors"

import { Slug } from "types/common"
import { AuthAction, AuthActionType } from "types/auth"
import {
  Event,
  EventID,
  EventsState,
  CreateEventAction,
  EventCreate,
  EventUpdate,
  EventAction,
  // EventSimple,
  SingleEventFailureAction,
  SingleEventSuccessAction,
  MultipleEventsSuccessAction,
  EventsActionType,
  FetchEventAction,
  FetchUserEventsAction,
  GenericEventAction,
  UpdateEventAction,
  NormalizedEvent,
  NormalizedEventWithProvider,
  NormalizedEventWithReviewsAndProvider,
} from "types/events"
import {
  ProviderID,
  ProviderFull,
  NormalizedProvider,
  ProvidersActionType,
  MultipleProvidersSuccessAction,
  SingleProviderSuccessAction,
} from "types/providers"
import { Review } from "types/reviews"
import {
  // UiToastAction,
  ToastType,
} from "types/ui"
import { User } from "types/user"

const updateStateAfterFailedEventAction = (state: EventsState, action: SingleEventFailureAction) => {
  return {
    ...state,
    isLoading: false,
    isUpdating: false,
    error: action.error,
  }
}

const updateStateAfterSuccessfulEventAction = (state: EventsState, action: SingleEventSuccessAction) => {
  return {
    ...state,
    isLoading: false,
    isUpdating: false,
    error: null,
    data: {
      ...state.data,
      [action.data.id]: normalizeEventsFromServer([action.data])[0],
    },
    eventSlugToIdLookup: {
      ...state.eventSlugToIdLookup,
      [action.data.slug]: action.data.id,
    },
    eventSlugToMetaLookup: {
      ...state.eventSlugToMetaLookup,
      [action.data.slug]: {
        lastFetchedAt: new Date().toISOString(),
        fourOhFour: false,
      },
    },
  }
}

const createStateUpdatesForMultipleEventsFromServer = (state: EventsState, events: Event[]) => {
  const meta = { lastFetchedAt: new Date().toISOString(), fourOhFour: false }
  const normalizedEvents = events?.length ? normalizeEventsFromServer(events) : []
  const eventSlugToIdLookup = normalizedEvents.reduce((acc, e) => {
    acc[e.slug] = e.id
    return acc
  }, {})
  const eventSlugToMetaLookup = normalizedEvents.reduce((acc, e) => {
    acc[e.slug] = meta
    return acc
  }, {})

  return {
    ...state,
    isLoading: false,
    isUpdating: false,
    error: null,
    data: {
      ...state.data,
      ...R.indexBy(R.prop("id"), normalizedEvents),
    },
    eventSlugToIdLookup: {
      ...state.eventSlugToIdLookup,
      ...eventSlugToIdLookup,
    },
    eventSlugToMetaLookup: {
      ...state.eventSlugToMetaLookup,
      ...eventSlugToMetaLookup,
    },
  }
}

const updateStateAfterSuccessfullyFetchingMultipleEvents = (
  state: EventsState,
  action: MultipleEventsSuccessAction
) => {
  return createStateUpdatesForMultipleEventsFromServer(state, action.data)
}

const updateStateAfterSuccessfullyFetchingProvider = (state: EventsState, action: SingleProviderSuccessAction) => {
  const events: Event[] = R.filter(
    (event) => isEventInstance(event),
    extractEventsFromProvider(action.data as ProviderFull)
  )
  return createStateUpdatesForMultipleEventsFromServer(state, events)
}

const updateStateAfterSuccessfullyFetchingMultipleProviders = (
  state: EventsState,
  action: MultipleProvidersSuccessAction
) => {
  const events: Event[][] = R.filter(
    // (event) => isEventInstance(event),
    (event) => Boolean(event),
    R.map((p: ProviderFull) => extractEventsFromProvider(p), action.data)
  )
  return createStateUpdatesForMultipleEventsFromServer(state, events.flat())
}

export default function eventsReducer(
  state = initialState.events,
  action: EventAction | AuthAction | MultipleProvidersSuccessAction | SingleProviderSuccessAction
) {
  switch (action.type) {
    case ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleProviders(state, action)
    case ProvidersActionType.FETCH_PROVIDER_SUCCESS:
      return updateStateAfterSuccessfullyFetchingProvider(state, action)
    case ProvidersActionType.CREATE_PROVIDER_SUCCESS:
      return updateStateAfterSuccessfullyFetchingProvider(state, action)
    case ProvidersActionType.UPDATE_PROVIDER_SUCCESS:
      return updateStateAfterSuccessfullyFetchingProvider(state, action)

    case EventsActionType.FETCH_EVENT:
      return { ...state, isLoading: true }
    case EventsActionType.FETCH_EVENT_SUCCESS:
      return updateStateAfterSuccessfulEventAction(state, action)
    case EventsActionType.FETCH_EVENT_FAILURE:
      return updateStateAfterFailedEventAction(state, action)
    case EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST:
      return { ...state, isLoading: true }
    case EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleEvents(state, action)
    case EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_FAILURE:
      return { ...state, isLoading: false, error: action.error }
    case EventsActionType.CREATE_EVENT:
      return { ...state, isLoading: true }
    case EventsActionType.CREATE_EVENT_SUCCESS:
      return updateStateAfterSuccessfulEventAction(state, action)
    case EventsActionType.CREATE_EVENT_FAILURE:
      return updateStateAfterFailedEventAction(state, action)
    case EventsActionType.UPDATE_EVENT:
      return { ...state, isUpdating: true }
    case EventsActionType.UPDATE_EVENT_SUCCESS:
      return updateStateAfterSuccessfulEventAction(state, action)
    case EventsActionType.UPDATE_EVENT_FAILURE:
      return updateStateAfterFailedEventAction(state, action)
    case EventsActionType.SET_EVENT_FOUR_OH_FOUR_SLUG:
      return {
        ...state,
        eventSlugToMetaLookup: {
          ...state.eventSlugToMetaLookup,
          [action.slug]: {
            lastFetchedAt: new Date().toISOString(),
            fourOhFour: true,
          },
        },
      }
    case AuthActionType.SIGN_USER_OUT:
      return initialState.events
    default:
      return state
  }
}

const setEventFourOhFourSlug = (slug: Slug) => ({
  type: EventsActionType.SET_EVENT_FOUR_OH_FOUR_SLUG,
  slug,
  error: "Event not found.",
})

export type CreateEventParams = { eventCreate: EventCreate }
export type CreateEventResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  CreateEventAction | FetchUserEventsAction // | IUserProvidersNeedRefresh
>
const createEvent = ({ eventCreate }: CreateEventParams): CreateEventResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/events/`,
        method: "POST",
        types: {
          REQUEST: EventsActionType.CREATE_EVENT,
          SUCCESS: EventsActionType.CREATE_EVENT_SUCCESS,
          FAILURE: EventsActionType.CREATE_EVENT_FAILURE,
        },
        options: {
          data: { event_create: eventCreate },
          params: {},
        },
        onSuccess: (res) => {
          // dispatch(Actions.fetchUserEvents())
          // dispatch(providerActions.userProvidersNeedRefresh() as IUserProvidersNeedRefresh)
          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)

          errorMessages.forEach((error) => {
            const toast = {
              title: "Could not create event",
              contents: makeUserFriendlyErrorMessage(error),
              type: ToastType.DANGER,
              link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error }
        },
      })
    )
  }
}

export type FetchUserEventsResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchUserEventsAction
>
const fetchUserEvents = (): FetchUserEventsResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/events/me/`,
        method: "GET",
        types: {
          REQUEST: EventsActionType.FETCH_USER_EVENTS,
          SUCCESS: EventsActionType.FETCH_USER_EVENTS_SUCCESS,
          FAILURE: EventsActionType.FETCH_USER_EVENTS_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => ({ success: false, status: res.status, error: res.error }),
      })
    )
  }
}

// export type FetchEventByIdParams = { eventId: EventID }
// export type FetchEventByIdResult = ThunkAction<Promise<ApiClientCallbackResponse>, StateShape, void, FetchEventAction>
// const fetchEventById = ({ eventId }: FetchEventByIdParams): FetchEventByIdResult => {
//   return (dispatch) => {
//     return dispatch(
//       apiClient({
//         url: `/events/id/${eventId}/`,
//         method: "GET",
//         types: {
//           REQUEST: EventsActionType.FETCH_EVENT,
//           SUCCESS: EventsActionType.FETCH_EVENT_SUCCESS,
//           FAILURE: EventsActionType.FETCH_EVENT_FAILURE,
//         },
//         options: {
//           data: {},
//           params: {},
//         },
//         onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
//         onFailure: (res) => ({ success: false, status: res.status, error: res.error }),
//       })
//     )
//   }
// }

export type FetchEventBySlugParams = { eventSlug: Slug }
export type FetchEventBySlugResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchEventAction | GenericEventAction
>
const fetchEventBySlug = ({ eventSlug }: FetchEventBySlugParams): FetchEventBySlugResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/events/slug/${eventSlug}/`,
        method: "GET",
        types: {
          REQUEST: EventsActionType.FETCH_EVENT,
          SUCCESS: EventsActionType.FETCH_EVENT_SUCCESS,
          FAILURE: EventsActionType.FETCH_EVENT_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => {
          dispatch(Actions.setEventFourOhFourSlug(eventSlug) as GenericEventAction)
          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type FetchEventListBySlugsParams = { eventSlugs: Slug[] }
export type FetchEventListBySlugsResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchEventAction | GenericEventAction
>
const fetchEventListBySlugs = ({ eventSlugs }: FetchEventListBySlugsParams): FetchEventListBySlugsResult => {
  return (dispatch) => {
    const slugs = eventSlugs.map((slug) => `&event_slug_list=${slug}`).join("")
    const slugParams = `?${slugs.slice(1)}`
    const url = `/events/by-slugs/${slugParams}`

    return dispatch(
      apiClient({
        // url: `/events/by-slugs/${slugParams}`,
        url,
        method: "GET",
        types: {
          REQUEST: EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST,
          SUCCESS: EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS,
          FAILURE: EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_FAILURE,
        },
        options: {
          data: {},
          params: {},
          // params: { event_slug_list: eventSlugs.join(",") },
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => {
          // dispatch(Actions.setEventFourOhFourSlug(eventSlug) as GenericEventAction)
          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

// export type FetchEventListByIdsParams = { eventIds: EventID[] }
// export type FetchEventListByIdsResult = ThunkAction<
//   Promise<ApiClientCallbackResponse>,
//   StateShape,
//   void,
//   FetchEventAction | GenericEventAction
// >
// const fetchEventListByIds = ({ eventIds }: FetchEventListByIdsParams): FetchEventListByIdsResult => {
//   return (dispatch) => {
//     return dispatch(
//       apiClient({
//         url: `/events/by-ids/`,
//         method: "GET",
//         types: {
//           REQUEST: EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST,
//           SUCCESS: EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS,
//           FAILURE: EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_FAILURE,
//         },
//         options: {
//           data: {},
//           params: {},
//           // params: { event_ids: eventIds.join(",") },
//         },
//         onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
//         onFailure: (res) => {
//           // dispatch(Actions.setEventFourOhFourSlug(eventSlug) as GenericEventAction)
//           return { success: false, status: res.status, error: res.error }
//         },
//       })
//     )
//   }
// }

export type UpdateEventParams = { eventId: EventID; eventUpdate: EventUpdate }
export type UpdateEventResult = ThunkAction<Promise<ApiClientCallbackResponse>, StateShape, void, UpdateEventAction>
const updateEventById = ({ eventId, eventUpdate }: UpdateEventParams): UpdateEventResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/events/id/${eventId}/`,
        method: "PUT",
        types: {
          REQUEST: EventsActionType.UPDATE_EVENT,
          SUCCESS: EventsActionType.UPDATE_EVENT_SUCCESS,
          FAILURE: EventsActionType.UPDATE_EVENT_FAILURE,
        },
        options: {
          data: { event_update: eventUpdate },
          params: {},
        },
        onSuccess: (res) => {
          const toast = {
            title: "Success!",
            contents: "Your event has been updated.",
            type: ToastType.SUCCESS,
          }
          dispatch(uiActions.addUiToast({ toast }))

          return { type: EventsActionType.UPDATE_EVENT, success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)
          errorMessages.forEach((error) => {
            const toast = {
              title: "Could not update event",
              contents: makeUserFriendlyErrorMessage(error),
              type: ToastType.DANGER,
              link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error }
        },
      })
    )
  }
}

export const Actions = {
  createEvent,
  fetchEventBySlug,
  fetchEventListBySlugs,
  // fetchEventById,
  fetchUserEvents,
  updateEventById,
  setEventFourOhFourSlug,
}

/* EVENT SELECTORS */
export const getNormalizedEventsFromListOfIds = (state: StateShape, eventIds: EventID[]): NormalizedEvent[] => {
  const idToEventMapping: Record<EventID, NormalizedEvent> = R.pick(
    Array.isArray(eventIds) ? eventIds.map((e) => Number(e)) : [],
    state.events.data
  )
  return R.values(idToEventMapping)
}

export const getEventIdsFromListOfSlugs = (state: StateShape, slugs: Slug[]): EventID[] => {
  const slugToIdMapping: Record<Slug, EventID> = R.pickBy((v, k) => slugs.includes(k), state.events.eventSlugToIdLookup)
  return R.values(slugToIdMapping)
}

export const selectEventWithProvider = (state: StateShape, eventId: EventID): NormalizedEventWithProvider => {
  const normalizedEvent = state.events.data[eventId]
  if (normalizedEvent) {
    const provider = state.providers.data[normalizedEvent.provider]
    return { ...normalizedEvent, provider }
  }

  return null
}

export const selectEventWithReviewsAndProvider = (
  state: StateShape,
  eventId: EventID
): NormalizedEventWithReviewsAndProvider => {
  const normalizedEventWithProvider = selectEventWithProvider(state, eventId)
  return {
    ...normalizedEventWithProvider,
    reviews: normalizedEventWithProvider?.reviews
      ? selectListOfReviewsFromReviewIdList(state, normalizedEventWithProvider?.reviews ?? [])
      : [],
  }
}

export const getEventsFromListOfSlugs = (state: StateShape, slugs: Slug[]): Event[] => {
  const eventIds = getEventIdsFromListOfSlugs(state, slugs ?? [])
  return getNormalizedEventsFromListOfIds(state, eventIds)
}

export const getEventsWithProviderFromListOfSlugs = (
  state: StateShape,
  slugs: Slug[]
): NormalizedEventWithProvider[] => {
  const events = getEventsFromListOfSlugs(state, slugs)
  const providerIds = events.map((e) => e.provider) as ProviderID[]
  const idToProviderMapping: Record<ProviderID, NormalizedProvider> = R.pick(providerIds, state.providers.data)

  return R.map(
    (e) => ({
      ...e,
      provider: isString(e.provider) || isNumber(e.provider) ? idToProviderMapping[e.provider] : e.provider,
      reviews: R.map((review: Review) => review.id, e.reviews),
    }),
    events
  )
}

export const getEventsWithProviderAndReviewsFromListOfSlugs = (
  state: StateShape,
  slugs: Slug[]
): NormalizedEventWithReviewsAndProvider[] => {
  const events = getEventsWithProviderFromListOfSlugs(state, slugs)
  return R.map(
    (e) => ({
      ...e,
      reviews: selectListOfReviewsFromReviewIdList(state, e.reviews),
    }),
    events
  )
}

/**
 * Not really a selector, but putting this here anyway since it's useful.
 */
export const selectUserReviewForEvent = (event: Event, user: User): Review | null => {
  if (!user) return null
  if (!event) return null

  return isUserInstance(user)
    ? R.find((r: Review) => Number(r.owner) === Number(user.id), event.reviews as Review[])
    : R.find((r: Review) => r.owner === user, event.reviews as Review[])
}
